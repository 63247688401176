import React from "react";

export function ChevronLeft({ className }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16 2L8.1607 11.5289C8.10976 11.5907 8.06934 11.6642 8.04177 11.745C8.01419 11.8258 8 11.9125 8 12C8 12.0875 8.01419 12.1742 8.04177 12.255C8.06934 12.3358 8.10976 12.4093 8.1607 12.4711L16 22"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
